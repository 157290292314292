import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Principal from "./components/page/Principal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import { SnackbarProvider, useSnackbar } from "notistack";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#633A8A",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Principal />
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

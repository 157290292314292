import woocommerceRestApi from "@woocommerce/woocommerce-rest-api";

export const apiWoo = new woocommerceRestApi({
  url: "https://nutriproyect.net",
  consumerKey: "ck_02900984fc611d8be4f91f544826441159eb3641",
  consumerSecret: "cs_1394f21e99355b7659a58e7afc299cbd9cafa2ec",
  version: "wc/v3",
});

export const formatNumber = (value) => {
  let dollarUSLocale = Intl.NumberFormat("en-US");

  //console.log("US Locale output: " + dollarUSLocale.format(value));
  return dollarUSLocale.format(value);
};

export const InvokeOrder = (url) => {
  window.location.href = url;
};

import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button } from "@mui/material";

export const AddressForm = ({ onClose, dataForm, open, onSetDataAdress }) => {
  //const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(dataForm || {});
  const onValueChange = (e) => {
    //props.onValueChange?.(e.target.name, e.target.value);
    if (e.target.name) {
      const ent = { ...formData };
      ent[e.target.name] = e.target.value;
      //console.log(ent);
      setFormData(ent);
    }
  };
  const handleClose = () => {
    onClose?.(formData);
  };
  const handlePayment = () => {
    onSetDataAdress?.(formData);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Shipping address</DialogTitle>
      <DialogContent>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} p={2}>
            <Grid xs={12} sm={6}>
              <TextField
                onChange={onValueChange}
                required
                id="email"
                name="email"
                label="E-mail"
                value={formData?.email}
                fullWidth
                autoComplete="email"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                onChange={onValueChange}
                required
                id="phone"
                name="phone"
                label="Phone"
                value={formData?.phone}
                fullWidth
                autoComplete="phone"
              />
            </Grid>

            <Grid xs={12} sm={6}>
              <TextField
                onChange={onValueChange}
                required
                id="first_name"
                name="first_name"
                label="First name"
                value={formData?.first_name}
                fullWidth
                autoComplete="fname"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                onChange={onValueChange}
                required
                id="last_name"
                name="last_name"
                label="Last name"
                value={formData?.last_name}
                fullWidth
                autoComplete="lname"
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                onChange={onValueChange}
                required
                id="address_1"
                name="address_1"
                label="Address line 1"
                value={formData?.address_1}
                fullWidth
                autoComplete="billing address-line1"
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                onChange={onValueChange}
                id="address_2"
                name="address_2"
                label="Address line 2"
                value={formData?.address_2}
                fullWidth
                autoComplete="billing address-line2"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                onChange={onValueChange}
                required
                id="city"
                name="city"
                value={formData?.city}
                label="City"
                fullWidth
                autoComplete="billing address-level2"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                onChange={onValueChange}
                id="state"
                name="state"
                value={formData?.state}
                label="State/Province/Region"
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                onChange={onValueChange}
                required
                id="postcode"
                name="postcode"
                value={formData?.postcode}
                label="Zip / Postal code"
                fullWidth
                autoComplete="billing postal-code"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                onChange={onValueChange}
                required
                id="country"
                name="country"
                label="Country"
                value={formData?.country}
                fullWidth
                autoComplete="billing country"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePayment} variant="contained" color="primary">
          Payment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressForm;

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { formatNumber } from "../../contants/AppConstants";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const InfoCard = ({ product, onAddToCart, ...props }) => {
  const price = product?.price || 0;
  const imageUrl = product?.images?.[0].src;
  const [cantProd, setCant] = React.useState(12);
  return (
    <Card p={2}>
      <CardActionArea
        sx={{ display: "flex", height: "220px" }}
        onClick={() => {
          if (product?.permalink) window.open(product.permalink, "_blank");
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: "50%" }}
          image={imageUrl}
          alt={product?.name}
          loading="lazy"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <h2 className="productTitle">{product?.name}</h2>
            <Typography
              component="div"
              variant="h6"
              sx={{ color: "var(--colorPrimary)" }}
            >
              ${formatNumber(price)}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "flex-end",
          pl: 1,
          pb: 1,
        }}
      >
        <Button
          style={{
            backgroundColor: "var(--colorPrimary)",
            borderRadius: "0 10px",
            color: "white",
            width: "120px",
            height: "30px",
            //textAlign: "center",
          }}
          disabled={cantProd < 12}
          onClick={() =>
            onAddToCart({ ...product, imageUrl: imageUrl }, cantProd)
          }
        >
          <AddShoppingCartIcon sx={{ margin: "10px" }} />$
          {formatNumber(cantProd * price)}
        </Button>
        <TextField
          variant="standard"
          title="Cant"
          type="number"
          size="small"
          color="primary"
          value={cantProd}
          onChange={(e) => {
            //console.log(e);
            let val = e.currentTarget.value;
            if (val <= 0) val = 12;

            setCant(val);
          }}
          sx={{ width: "70px" }}
        />
      </Box>
    </Card>
  );
};
